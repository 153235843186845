import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "characterCounter",
    "messageCounter",
    "message",
    "urlReplaced",
  ];

  connect() {
    this.characterCount();
  }
  characterCount() {
    const controller = this;
    setTimeout(function () {
      const string = controller.messageTarget.value;
      const regExp = /http\S{96,}/gi;
      const longUrlMatches = string.match(regExp) || [];
      const hasLongUrl = !!longUrlMatches.length;
      const processLongUrls =
        controller.hasUrlReplacedTarget &&
        controller.data.has("shortUrlLength");
      const shortUrlLength = processLongUrls
        ? parseInt(controller.data.get("shortUrlLength"))
        : 0;
      if (processLongUrls) {
        const prefix =
          longUrlMatches.length === 1
            ? ". 1 URL"
            : `. ${longUrlMatches.length} URLs`;
        controller.urlReplacedTarget.textContent = hasLongUrl
          ? `${prefix} will be shortend.`
          : "";
      }

      const stringLength = processLongUrls
        ? controller.messageTarget.value.replace(
            regExp,
            "a".repeat(shortUrlLength)
          ).length
        : controller.messageTarget.value.length;

      const remainder = stringLength % 160;
      const messages = Math.ceil(stringLength / 160);
      controller.characterCounterTarget.textContent = 160 - remainder;
      controller.messageCounterTarget.textContent =
        remainder == 0 ? ` ${messages + 1}` : ` ${messages}`;
    }, 50);
  }
}
