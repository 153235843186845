import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "errorText", "phoneNumber" ]

  connect() {
    this.connectChannel()
  }

  clearForm(event) {
    $(event.currentTarget).find('input').val('')
    this.errorTextTarget.innerText = ""
    this.errorTextTarget.classList.remove('error')
    this.phoneNumberTarget.classList.remove('error')
  }

  displayError(event) {
    const phoneNumber = this.phoneNumberTarget.value
    this.phoneNumberTarget.classList.add('error')
    this.errorTextTarget.classList.add('error')
    switch (phoneNumber) {
      case "":
        this.errorTextTarget.innerText = "The phone number field is blank."
        break;
    
      default:
        this.errorTextTarget.innerText = `${phoneNumber} is not recognized as a valid phone number.`
        break;
    }
  }

  insertDom(htmlString) {
    const targetElement = document.getElementById('locate-error')
    const div = document.createElement('div');
    div.classList.add('cabled-in')
    div.innerHTML = htmlString.trim();
    targetElement.insertAdjacentElement('afterend', div)
  }

  connectChannel(){
    const element = this.element
    const organization = element.dataset.organization
    const channelId = `locate-${organization}`
    const tab = this.tab
    const showUnread = this.showUnread
    const insertDom = this.insertDom
    const dispatchLocalNotification = this.dispatchLocalNotification.bind(this)
    if (App[channelId] == undefined) {
      App[channelId] = App.cable.subscriptions.create({channel: "LocateDashboardChannel", room: organization}, {
        connected() {},
        // Called when the subscription is ready for use on the server

        disconnected() {},
        // Called when the subscription has been terminated by the server

        received(data) {
          $("#locate-empty").remove()
          const $data = $(data)
          const id = $data.attr("id")
          if ($("#locate-tab").find(`#${id}`).length == 0) {
            insertDom(data)
          } else{
            $(`#${id}`).replaceWith( $data )
          }
          window.setupPage()
          showUnread(tab)
          dispatchLocalNotification()
          const container = document.getElementById(id).closest('.location-request')
          if (container) {
            if (id.endsWith('-messages')) {
              const thisTab = container.querySelector('[data-location-request-target="messageTab"]')
              showUnread(thisTab)
              document.getElementById(`${id}-input`).focus()
            } else {
              const thisTab = container.querySelector('[data-location-request-target="locationTab"]')
              showUnread(thisTab)
            }
          }
        }
      }
      );
    }
  }

  dispatchLocalNotification() {
    const windowIsVisible = document.visibilityState == 'visible'
    const activeTab = this.tab.classList.contains('active')
    if ( windowIsVisible && activeTab) {
      return
    }
    this.dispatch('notify', { detail: "Locate Request Updated" })
  }

  get tab() {
    return document.querySelector(`.tabs-dashboard [data-tab="locate"]`)
  }

  removeUnread() {
    this.tab.classList.remove('unread')
  }

  showUnread(tab){
    if (!tab.classList.contains('active')) {
      tab.classList.add("unread")
    }
  }
}
