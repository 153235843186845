/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const fetchNotification = /* GraphQL */ `
  query FetchNotification(
    $contactId: String!
    $notificationSk: String!
    $organizationId: String!
  ) {
    fetchNotification(
      contactId: $contactId
      notificationSk: $notificationSk
      organizationId: $organizationId
    ) {
      contactId
      createdAt
      images {
        fileName
        path
        previewPath
      }
      message
      notificationId
      notificationSk
      organizationId
      poll {
        answerId
        closesAt
        id
        question
      }
      readAt
      receivedAt
      recordingUrl
      sender {
        avatarUrl
        initial
        type
      }
      senderName
    }
  }
`;
export const fetchNotifications = /* GraphQL */ `
  query FetchNotifications(
    $contactId: String!
    $notificationSk: String!
    $operator: String!
    $organizationId: String!
  ) {
    fetchNotifications(
      contactId: $contactId
      notificationSk: $notificationSk
      operator: $operator
      organizationId: $organizationId
    ) {
      contactId
      createdAt
      images {
        fileName
        path
        previewPath
      }
      message
      notificationId
      notificationSk
      organizationId
      poll {
        answerId
        closesAt
        id
        question
      }
      readAt
      receivedAt
      recordingUrl
      sender {
        avatarUrl
        initial
        type
      }
      senderName
    }
  }
`;
export const getNofitication = /* GraphQL */ `
  query GetNofitication(
    $contactId: String!
    $notificationSk: String!
    $organizationId: String!
  ) {
    getNofitication(
      contactId: $contactId
      notificationSk: $notificationSk
      organizationId: $organizationId
    ) {
      contactId
      createdAt
      images {
        fileName
        path
        previewPath
      }
      message
      notificationId
      notificationSk
      organizationId
      poll {
        answerId
        closesAt
        id
        question
      }
      readAt
      receivedAt
      recordingUrl
      sender {
        avatarUrl
        initial
        type
      }
      senderName
    }
  }
`;
export const listChannelItems = /* GraphQL */ `
  query ListChannelItems(
    $channelId: String
    $lastTimestamp: String
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listChannelItems(
      channelId: $channelId
      lastTimestamp: $lastTimestamp
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        channelId
        createdAt
        id
        memberId
        updatedAt
        deletedAt
        replacementRecord
        author {
          id
          avatar
          initial
          name
        }
        chatImage2 {
          key
          extension
          contentType
          heightPixels
          widthPixels
          fileName
        }
        chatImage {
          url
          version
          extension
          publicId
          heightPixels
          widthPixels
          fileName
        }
        chatMessage {
          text
        }
        chatAttachment2 {
          key
          fileName
          extension
          contentType
        }
        chatAttachment {
          url
          publicId
          fileName
          extension
        }
        chatVideo {
          url
          publicId
          fileName
          extension
        }
        chatNotification {
          id
          text
        }
      }
      nextToken
    }
  }
`;
