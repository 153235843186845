import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "message", "pollSelect", "pollDuration", 'reset', 'submit', 'sendVia' ]

  connect() {
    $('#groups-to-notify').dropdown({ fullTextSearch: true })
  }

  showReset(){
    //this.resetTarget.classList.remove('no-show')
    //$(this.pollSelectTarget).dropdown('add optionValue', 'Washington')
  }

  resetSelect(){
    $(this.pollSelectTarget).dropdown('restore defaults')
    $(this.pollDurationTarget).dropdown('restore defaults')
  }

  setDuration(event){
    const selection = event.target
    if (!selection.parentNode.parentNode.classList.contains('poll')) {
      return
    }
    const value = selection.dataset.value
    if (value === 'none') {
      this.resetSelect()
      return
    }
    const options = Array.from(this.pollSelectTarget.options)
    const option = options.find(e => e.value === value)
    const duration = option.dataset.duration
    if (duration) {
      $('.ui.dropdown.duration').dropdown('set selected', duration)
    }
  }

  setContent(){
    const content = this.data.get("content")
    if (content && content.length) {
      this.messageTarget.value = content
    }
    this.validate()
  }

  validate(){
    
    const list = $(this.sendViaTarget)
    const listItems = list.find('input:checked')
    const messageLength = this.messageTarget.value.trim().length
    const isUploading = this.element.querySelector('[data-uploading="true"]')
    if (isUploading || listItems.length == 0 || messageLength == 0) {
      this.disableSubmit()
    }else{
      this.enableSubmit()
    }
  }

  disableSubmit() {
    this.submitTarget.classList.add('disabled');
  }

  enableSubmit() {
    this.submitTarget.classList.remove('disabled');
  }

}
