import {
  Controller
} from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.appendSignature()
  }

  appendSignature() {
    const signature = this.data.get('signature')
    this.element.click()
    this.element.focus()
    if (signature && signature.length > 0) {
      const pos = this.element.value.length
      this.element.value = `${this.element.value} ${signature}`
      this.element.setSelectionRange(pos, pos)
    } else {
      const pos = this.element.value.length
      this.element.setSelectionRange(pos, pos)
    }
    this.element.dispatchEvent(new Event("change"))
  }

}