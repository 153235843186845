import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "edit", "show", "form", "requiredField" ]
  connect() {
    const errorField = this.element.querySelector('.has-error')
    if (errorField || this.hasEmptyRequiredField) {
      this.toggleForm()
    }
  }

  closeForm(){
    if (this.hasShowTarget && this.isOpen) {
      this._closeForm()
    }
  }

  toggleForm(event) {
    if (event) {
      event.preventDefault()
    }
    if (this.isMobileAdminOpen) {
      return
    }
    this.showTarget.classList.toggle('no-show')
    this.editTarget.classList.toggle('no-show')
    this.formTarget.classList.toggle('no-show')
    this.element.dispatchEvent(new CustomEvent('section-toggled', {bubbles: true, cancelable: true}));
  }

  _closeForm(){
    this.showTarget.classList.remove('no-show')
    this.editTarget.classList.remove('no-show')
    this.formTarget.classList.add('no-show')
  }

  get isOpen(){
    return this.showTarget.classList.contains('no-show')
  }

  get hasEmptyRequiredField(){
    return this.hasRequiredFieldTarget ? this.requiredFieldTargets.filter(f => f.value === '').length : false
  }

  get isMobileAdminOpen(){
    return this.application.getControllerForElementAndIdentifier(document.querySelector('section.admin'), "effects").isAdminOpen
  }

}
