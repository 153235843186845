import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $(this.element).dropdown({
      onChange: function (value, text, $choice) {
        if ($choice && typeof $choice != "string") {
          var event = new CustomEvent("dropdownSelection", {
            bubbles: true,
            cancelable: true,
          });
          var element = $choice.get(0);
          element.dispatchEvent(event);
        }
      },
    });
  }
}
