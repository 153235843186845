import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["preview"]

  connect() {
    // debugger
    this.addResidentName()
  }

  addResidentName(){
    if (this.prependResidentName) {
      this.previewTarget.innerHTML = this.data.get('text').replace(/^\<p\>/, `<p>${this.nameToPrepend}`)
    }else{
      this.previewTarget.innerHTML = this.data.get('text')
    }
  }

  get prependResidentName(){
    return document.getElementById('organization_append_contact_name').checked
  }

  get nameToPrepend(){
    return "Hi [Name] "
  }
}
