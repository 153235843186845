import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "card", "errors", "button" ]
  connect() {
    this.stripe    = Stripe(this.data.get("public-key"))
    const elements = this.stripe.elements()
    const style    = {
      base: {
        // Add your base input styles here. For example:
        fontSize: '16px',
        lineHeight: '24px'
      }
    };
    this.card = elements.create("card", { style: style, hidePostalCode: true })
    this.card.mount(this.cardTarget)
    const cardWrapper = this.cardTarget
    const errorElement = this.errorsTarget
    const submitButton = this.buttonTarget
    this.card.on('change', function (event) {
      errorElement.textContent = ''
      submitButton.classList.remove('disabled')
      submitButton.removeAttribute('disabled')
      if (event.error) {
        errorElement.textContent = event.error.message
        submitButton.classList.add('disabled')
        
      }
    })
  }

  changeValues(event) {
    
    if (event.error) {
      this.errorsTarget.textContent = event.error.message
    } else {
      this.errorsTarget.textContent = ""
    }
  }

  stripeTokenHandler(token) {
    const hiddenInput = document.createElement("input")
    hiddenInput.setAttribute("type", "hidden")
    hiddenInput.setAttribute("name", "stripeToken")
    hiddenInput.setAttribute("value", token.id)
    this.element.appendChild(hiddenInput)
    this.element.submit()
  }

  createToken(event) {
    event.preventDefault()
    this.stripe.createToken(this.card).then((result) => {
      if (result.error) {
        const error = result.error
        this.errorsTarget.textContent = error.message
      } else {
        this.stripeTokenHandler(result.token)
      }
    })
  }
}