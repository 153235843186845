import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal", "membersTrialLink", "memberTrialLink", "manageMembersLink", "listsTrialLink", "manageListsLink",  "notificationsTrialLink", "sendNotificationTrialButton", "conferencesTrialLink", "startConferenceTrialButton"]
  connect() {
    this.selectNext()
  }

  selectNext() {
    const nextLink = document.querySelector(".trial-steps.not-complete .trial-step.not-complete")
    if (nextLink) {
      nextLink.classList.add('next')
    }
  }

  startStep(event) {
    const step = event.target
    switch (step) {
      case this.membersTrialLinkTarget:
        this.toggleElement(this.manageMembersLinkTarget)
        break;
      case this.memberTrialLinkTarget:
        this.toggleElement(this.manageMembersLinkTarget)
        break;
      case this.listsTrialLinkTarget:
        this.toggleElement(this.manageListsLinkTarget)
        break;
      case this.notificationsTrialLinkTarget:
        this.toggleElement(this.sendNotificationTrialButtonTarget)
        break;
      case this.conferencesTrialLinkTarget:
        this.toggleElement(this.startConferenceTrialButtonTarget)
        break;
      default:
        
    }
  }

  toggleElement(element) {
    element.classList.remove("next-trial")
    const newone = element.cloneNode(true);
    element.parentNode.replaceChild(newone, element);
    newone.classList.add('next-trial')
  }

  get modalsController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "modals")
  }
}
