import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage/src/direct_upload";

export default class extends Controller {
  static targets = ["avatarLabel", "fileInput"];
  static values = { deleteAvatarUrl: String };

  connect() {}

  indicateUploading() {
    this.avatarLabelTarget.classList.add("is-loading");
    this.avatarLabelTarget.textContent = "Uploading...";
  }

  indicateProcessing() {
    this.avatarLabelTarget.classList.add("is-processing");
    this.avatarLabelTarget.textContent = "Processing...";
  }

  async uploadAvatar(event) {
    const file = event.target.files[0];
    const upload = new DirectUpload(
      file,
      this.fileInputTarget.dataset.directUploadUrl
    );

    upload.create((error, blob) => {
      if (error) {
        
      } else {
        this.indicateProcessing()
        const hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("value", blob.signed_id);
        hiddenField.name = this.fileInputTarget.name;
        this.element.appendChild(hiddenField);
        this.fileInputTarget.remove()
        this.element.submit();
      }
    });
  }

  async removeAvatar(event) {
    event.preventDefault();
    event.stopPropagation();
    
    await fetch(this.deleteAvatarUrlValue, { method: "DELETE" });
    location.reload();
  }
}
