import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['toggleHint', 'alphanumericCodeField', 'inboundCallRecordingField', 'conferenceCallButton', 'conferenceCallSection','pagerNotificationButton', 'pagerNotificationSection', 'notificationButton', 'noWorkflowActions', 'smsResponse', 'smsResponseField', 'noConferenceLine']
  connect() {
    this.toggle()
  }

  cancelNew(event){
    event.preventDefault()
    const closest = event.target.closest('div.action')
    closest.parentNode.removeChild(closest)
    this.toggleButtons()
  }

  toggle() {
    this.toggleButtons()
    this.updateRecordingOptions()
    this.toggleHints()
    this.toggleSmsResponse()
  }

  toggleHints(){
    this.toggleHintTargets.forEach(wrapper => {
    const checkbox = wrapper.querySelector('input[type="checkbox"]')
    const hint = wrapper.querySelector('.hint')
     if (checkbox.checked) {
       this._show(hint)
     } else {
       this._hide(hint)
     }
    });
  }

  checkIfDisabled(event){
    if (event.target.classList.contains("disabled")) {
      event.preventDefault()
      event.stopImmediatePropagation()
      return false
    }
  }

  updateRecordingOptions(){
    if (this.isActivateByInboundcallRecording) {
      for (const element of this.alphanumericCodeFieldTargets) {
        this._hide(element)
      }
      for (const element of this.inboundCallRecordingFieldTargets) {
        this._show(element)
      }
    } else {
      for (const element of this.alphanumericCodeFieldTargets) {
        this._show(element)
      }
      for (const element of this.inboundCallRecordingFieldTargets) {
        this._hide(element)
      }
    }
  }

  toggleButtons() {
    const conferenceCallButton = this.conferenceCallButtonTarget
    const pageButton = this.pagerNotificationButtonTarget
    this._enable(this.notificationButtonTarget)
    if (this.hasConferenceCallSectionTarget) {
      this._disable(conferenceCallButton)
    }else {
      this._enable(conferenceCallButton)
    }
    if (this.hasPagerNotificationSectionTarget) {
      this._disable(pageButton)
    }else{
      this._enable(pageButton)
    }
    if (this.hasNoConferenceLines) {
      this._disableWithNoConferenceLine()
    }
  }

  toggleSmsResponse(){
    if (this.hasSmsResponseTarget) {
      const triggerBySms = this.smsResponseTarget.checked
      triggerBySms ? this.showSmsResponse() : this.hideSmsResponse()
    } else {
      this.hideSmsResponse()
    }
  }

  get requiresRecording(){
    return document.getElementById('activation_workflow_activate_by_inbound_call_recording')
  }

  get activateBy() {
    if (this.requiresRecording && this.requiresRecording.checked) {
      return "inbound_call_recording"
    } else {
      return document.getElementById('activate-by').value
    }
  }

  get isActivateByInboundcallRecording(){
    return this.activateBy === 'inbound_call_recording'
  }

  get isActivateByAlphanumericCode(){
    return this.activateBy === 'alphanumeric_code'
  }

  get hasNoConferenceLines(){
    return this.data.has('conference-line-count') && this.data.get('conference-line-count') == "0"
  }

  _hide(element){
    element.classList.add('no-show')
  }

  _show(element){
    element.classList.remove('no-show')
  }

  _disable(element){
    const string = element.innerHTML
    element.classList.add('disabled')
    element.innerHTML = `${string.replace(/^Add/, '').replace(/ added$/, '')} added`
  }

  _disableWithNoConferenceLine(){
    this.conferenceCallButtonTarget.classList.add('disabled')
    this.conferenceCallButtonTarget.innerHTML = 'Conference Unavailable'
    this.noConferenceLineTarget.classList.remove('no-show')
  }

  _enable(element){
    const string = element.innerHTML
    element.classList.remove('disabled')
    element.innerHTML = `Add ${string.replace(/^Add /, '').replace(/ added$/, '')}`
  }

  isVisible(element){
    return !element.classList.contains('no-show')
  }

  showSmsResponse(){
    for (const element of this.smsResponseFieldTargets) {
      this._show(element)
    }
  }

  hideSmsResponse(){
    for (const element of this.smsResponseFieldTargets) {
      this._hide(element)
    }
  }
}
