import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["table"]
  connect() {
    this.resetTable()
  }

  scrolling() {
    if (this.atBottom && this.active && !this.loadingMore && !this.loadingDone) {
      this.loadingMore = 'yes'
      this.pageNumber++
      this.loadData()
    }
  }

  resetTable(){
    this.pageNumber = 1
    this.loadingDone = ''
    this.loadingMore = ''
    Array.from(this.element.querySelectorAll("tr:not(.heading)")).forEach( e => e.parentNode.removeChild(e))
    this.loadData()
  }

  loadData() {
    const path = `${this.data.get("path")}`
    const externalParams = this.externalParams
    const params = `page=${this.pageNumber}&` + (this.formElement ? (`${this.formElement.dataset["queryFormQueryParams"]}&` || '') : '') + Object.keys(externalParams).map(key => key + '=' + externalParams[key]).join('&')
    return $.ajax({
      url: path,
      type: "GET",
      data: params,
      dataType: 'script'
    })
  }

  get active() {
    return this.element.classList.contains('active')
  }

  get atBottom() {
    const body = document.body
    const html = document.documentElement
    const height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
    return (window.innerHeight + Math.ceil(window.pageYOffset)) >= height
  }

  get pageNumber() {
    if(this.data.has('pageNumber')) {
      return parseInt(this.data.get("pageNumber"))
    }else{
      this.pageNumber = 1
      return 1
    }
  }

  get loadingMore(){
    return this.data.get('loadingMore')
  }
  set loadingMore(value){
    this.data.set('loadingMore', value)
  }

  get loadingDone(){
    return this.data.get('loadingDone')
  }

  set loadingDone(value){
    this.data.set('loadingDone', value)
  }

  set pageNumber(value) {
    this.data.set("pageNumber", value)
  }

  get formElement() {
    return document.querySelector('[data-controller="query-form"]')
  }

  get externalParams(){
    return this.data.has("params") ? JSON.parse(this.data.get("params")) : {};
  }
}
