import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    iso8601: String,
    isDate: Boolean,
    timeFormat: Object,
  };

  connect() {
    if (this.hasIso8601Value) {
      if (this.isDateValue) {
        this.displayDate();
      } else {
        this.displayTime();
      }
    }
  }

  displayTime() {
    const time = new Date(this.iso8601Value);
    const timeString = time.toLocaleTimeString([], this.timeFormatOptions);
    this.element.textContent = timeString.replace(/ /g, "");
    
  }

  displayDate() {
    const date = new Date(this.iso8601Value);
    const options = { month: "short", day: "numeric", year: "numeric" };
    const dateString = date.toLocaleDateString([], options);
    const today = this.today;
    const yesterday = this.yesterday;
    if (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    ) {
      this.element.textContent = "Today";
    } else if (
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear()
    ) {
      this.element.textContent = "Yesterday";
    } else {
      this.element.textContent = dateString;
    }
  }

  get isTwelveHourTimeSetting() {
    return document.body.classList.contains("twelve-hour-time");
  }

  get timeFormatOptions() {
    const defaults = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: this.isTwelveHourTimeSetting,
    };
    return this.hasTimeFormatValue
      ? { ...defaults, ...this.timeFormatValue }
      : defaults;
  }

  get today() {
    return new Date();
  }

  get yesterday() {
    return new Date(Date.now() - 864e5);
  }
}
