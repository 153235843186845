import { Controller } from "@hotwired/stimulus"
import loadMembers from "../loadMembers"

const waiting = "<div class='spinner'><div class='double-bounce1'></div><div class='double-bounce2'></div></div>"
const tabletWidth = 1024

export default class extends Controller {
  static targets = [ "input", "list", "contactTab"]

  connect() {
    this.searchMembers()
    this.searchTimeout = null
  }

  searchMembers(){
    if (this.hasListTarget) {
      const $memberList = $(this.listTarget)
      const scope = this.data.get('scope')
      const appendMember = this.appendMember
      this.pageNumber = 1
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout( () =>  loadMembers(this.inputTarget, this.pageNumber, scope).done(data => {
        $.each(data, function( index, value ) {
          appendMember($memberList, value)
        });
      }), 350)
    }
  }

  desktop(){
    return window.innerWidth >= tabletWidth
  }

  appendMember($memberList, value){
    const $newEl = $(value.html_tag)
    const id = $newEl.attr('id')
    if (!document.getElementById(id)) {
      $memberList.append(value.html_tag)      
    }
  }

  setListHeight(){
    const height = this.listHeight
    this.element.querySelectorAll('.list-box').forEach(e => e.style.height = `${height}px`)
  }

  get listHeight(){
    // header - footer - search - tabs - searchPaddingTop - 16
    const offsetHeight = this.element.offsetHeight
    return offsetHeight - 80 - 70 - 48 - 56 - 24 - 16
  }

  get pageNumber(){
    return parseInt(this.data.get('pageNumber') || '1')
  }
  set pageNumber(value){
    this.data.set('pageNumber', value)
  }

  get loadingMore(){
    return this.data.get('loadingMore')
  }
  set loadingMore(value){
    this.data.set('loadingMore', value)
  }

  get isContactsActive() {
    return this.contactTabTarget.classList.contains('active')
  }

  loadMore(){
    if (this.loadingMore == true) {
      return
    }
    const $memberList = $(this.listTarget)
    const appendMember = this.appendMember
    const scope = this.data.get('scope')
    this.loadingMore = true
    this.pageNumber++
    loadMembers(this.inputTarget, this.pageNumber, scope).done(data => {
      this.loadingMore = false
      $.each(data, function( index, value ) {
        appendMember($memberList, value)
      });
    })
  }
  
  infiniteScroll(event) {
    if (!this.isContactsActive) {
      return
    }
    const element = event.currentTarget
    if (element.scrollHeight - Math.ceil(element.scrollTop) <= element.clientHeight) {
      this.loadMore()
    }
  }
}
