import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { selector: String, id: String }

  findAndClick(event) {
    event.preventDefault()
    const element = this.hasSelectorValue ? document.querySelector(this.selectorValue) : document.getElementById(this.idValue)
    element.click()
  }
}
