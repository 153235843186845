import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.modalsController._openModal(this.element.getAttribute('id'))
    this.connectToChannel(this.element.getAttribute('id'))
    //only do this if the poll is open!!
  }

  openNotification(){
    this.modalsController.goForward('send-notification')
  }

  openConference(){
    this.modalsController.goForward('conference-call-preview')
  }

  get modalsController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "modals")
  }

  connectToChannel(room){
    if (App[room] == undefined){
      App[room] = App.cable.subscriptions.create({channel: "PollResponseTableChannel", room: room}, {
        connected() {},
        // Called when the subscription is ready for use on the server

        disconnected() {},
        // Called when the subscription has been terminated by the server

        received(data) {
          const $data = $(data)
          const $element = $data.data('room')
          const $content = $data.first()
          $(`#${room}`).find(".modal-content").replaceWith($content)
        }
      });
    }
  }

}
