/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateChannel = /* GraphQL */ `
  subscription OnCreateChannel($organizationId: String!) {
    onCreateChannel(organizationId: $organizationId) {
      createdAt
      id
      name
      organizationId
    }
  }
`;
export const onCreateChannelItem = /* GraphQL */ `
  subscription OnCreateChannelItem($channelId: String!) {
    onCreateChannelItem(channelId: $channelId) {
      author {
        avatar
        id
        initial
        name
      }
      channelId
      chatImage2 {
        key
        extension
        contentType
        widthPixels
        heightPixels
        fileName
      }
      chatImage {
        url
        version
        extension
        publicId
        heightPixels
        widthPixels
        fileName
      }
      chatAttachment2 {
        key
        fileName
        extension
        contentType
      }
      chatAttachment {
        url
        publicId
        fileName
        extension
      }
      chatVideo {
        url
        publicId
        fileName
        extension
      }
      chatMessage {
        text
      }
      chatNotification {
        id
      }
      createdAt
      id
      memberId
      updatedAt
      deletedAt
      replacementRecord
    }
  }
`;
