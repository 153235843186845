import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['textToVoice', 'textMessage', 'smartConnect' ]

  updateSendVia(event) {
    const changed = event.target
    if (this.hasSmartConnectTarget) {
      if (changed === this.smartConnectTarget) {
        this.textMessageTarget.checked = false
        this.textToVoiceTarget.checked = false
      } else {
        this.smartConnectTarget.checked = false
      }
    }
  }
}
