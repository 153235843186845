import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "button"]
  static values = { check: String }
  connect() {
    this.inputTarget.placeholder = this.checkValue
  }

  verify() {
    if (this.inputTarget.value == this.checkValue) {
      this.buttonTarget.disabled = false
    } else {
      this.buttonTarget.disabled = true
    }
  }
}
