import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
  }

  submitForm(event){
    this.form.requestSubmit()
  }

  get form(){
    if (this.element.tagName == 'FORM') {
      return this.element
    } else {
      return this.element.parentElement.querySelector('form')
    }
  }

}
