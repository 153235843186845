import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "next", 'form' ]
  connect() {
    this.scrollToTop()
  }

  save () {
    this.form.requestSubmit()
  }

  updateValueAndSave (event) {
    event.preventDefault()
    const target = event.target
    const selector = target.dataset.updateTargetSelector
    const value = target.dataset.updateValue
    const element = this.form.querySelector(selector)
    if (element) {
      element.value = value
    }
    this.save()
  }

  get form () {
    return this.formTarget
  }

  next() {
    this.save()
  }

  openDestroyModal(event){
    event.preventDefault()
    $('#cancel-notification-modal').modal('show')
  }

  scrollToTop() {
    window.scrollTo(0, 0)
  }

}
