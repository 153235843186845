import { Controller } from "@hotwired/stimulus"
import { useIntersection } from 'stimulus-use'

export default class extends Controller {
  connect() {
    useIntersection(this)
  }

  appear(entry, observer) {
    if (this.element.classList.contains("async-status")) {
      this.updateStatus()
    }
  }

  updateStatus() {
    const parent = this.element.closest(".modal-content") || this.element.parentElement
    const children = parent.querySelectorAll(".async-status")
    const index = Array.from(children).indexOf(this.element)
    const delay = 100 * index
    const _this = this
    setTimeout(() => {  fetch(_this.data.get("url"))
    .then(response => response.json())
    .then(status => {
      _this.element.classList.remove("async-status")
      _this.element.classList.add(status["duty_status"])
    }) }, delay);
  }
}
