import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "navBar" ]
  connect() {
    const sticky = this.addSticky
    const navBar = this.navBarTarget
    const offSet = window.innerWidth > 1000 ? 145 : 100
    window.onscroll = function() {sticky(navBar, offSet)};
  }

  addSticky(target, sticky) {
    if (window.pageYOffset >= sticky) {
       target.classList.add("card-actions-fixed")
    } else {
       target.classList.remove("card-actions-fixed");
    }
  }
}
