import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    this.connectChannel()
  }

  updateDom(parentElement, htmlString) {
    const firstMessage = parentElement.children[0]
    const div = document.createElement('div');
    div.classList.add('cabled-in')
    div.innerHTML = htmlString.trim();
    if (firstMessage) {
      parentElement.insertBefore(div, firstMessage)
    }
  }

  connectChannel() {
    const element = this.element
    const organization = element.dataset.organization
    const channelId = `${organization}-text-messages`
    const updateDom = this.updateDom
    const showUnread = this.showUnread.bind(this)
    const dispatchLocalNotification = this.dispatchLocalNotification.bind(this)
    if (App[channelId] == undefined) {
      App[channelId] = App.cable.subscriptions.create({channel: "TextMessageChannel", room: channelId}, {
        connected() {},
        // Called when the subscription is ready for use on the server

        disconnected() {},
        // Called when the subscription has been terminated by the server

        received(data) {
          updateDom(element, data)
          showUnread()
          dispatchLocalNotification(data)
        }
      }
      );
    }
  }

  get tab(){
    return document.querySelector(`.tabs-dashboard [data-tab="text-messages"]`)
  }

  removeUnread() {
    this.tab.classList.remove('unread')
  }

  showUnread(){
    const tab = this.tab
    if (!tab.classList.contains('active')) {
      tab.classList.add("unread")
    }
  }

  dispatchLocalNotification(htmlString) {
    const windowIsVisible = document.visibilityState == 'visible'
    const activeTab = this.tab.classList.contains('active')
    const isInboundMessage = htmlString.includes('text-message inbound')
    console.log("windowIsVisible", windowIsVisible)
    console.log("activeTab", activeTab)
    console.log("isInboundMessage", isInboundMessage)
    if ( windowIsVisible && activeTab) {
      return
    }
    if(isInboundMessage){
      const otherController = this.application.getControllerForElementAndIdentifier(document.querySelector('body'), 'local-notification')
      otherController.notify({ detail: "New Notification Received" })
    }
  }
}
