import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['textField', 'player', 'tempUrl']
  connect() {
  }

  async generateTempUrlBeforeSubmit(event) {
    if (this.data.has('previewText') && this.data.get('previewText') == this.textFieldTarget.value) {
      return
    }else{
      event.preventDefault()
      await this.generatePreview()
      event.target.submit()
    }
  }

  async previewSpokenText(event) {
    event.preventDefault()
    event.stopPropagation()
    const link = event.target
    if (link.classList.contains('disabled')) {
      return
    }
    link.classList.add('disabled')
    await this.generatePreview()
    link.classList.remove('disabled')
    this.playPreview()
  }

  async generatePreview(){
    const text = this.textFieldTarget.value
    this.data.set('previewText', text)
    const tempUrl = this.tempUrlTarget
    if (text.length == 0) {
      return
    }
    await fetch(this.data.get("polly-url"), {
      body: JSON.stringify({"text": text}),
      method: 'POST',
      mode: 'cors',
      dataType: 'json'})
      .then(response => response.json())
      .then(data => {
        tempUrl.value = data.url
    })
  }

  playPreview() {
    // alert(url)
    const audioPlayer = document.createElement("audio");
    this.playerTarget.innerHTML = ''
    audioPlayer.className = "no-show";
    audioPlayer.src = this.tempUrlTarget.value
    audioPlayer.setAttribute("controls", "")
    this.playerTarget.appendChild(audioPlayer)
    audioPlayer.play()
  }

}
