import { Controller } from "@hotwired/stimulus"
// import Rails from 'rails-ujs'


export default class extends Controller {
  static targets = ['locations']
  initialize() {
    this.legend = document.getElementById('legend');
    this.infoWindowPositioned = null
  }
  connect() {
    const element = document.getElementById('map')
    const updateData = this.updateData
    const locationId = element.dataset.locationId
    if (App[locationId] == undefined) {
      App[locationId] = App.cable.subscriptions.create({channel: "LocationNotificationsChannel", room: locationId}, {
        connected() {},
        // Called when the subscription is ready for use on the server

        disconnected() {},
        // Called when the subscription has been terminated by the server

        received(data) {
          updateData(data)
        }
      }
      );
    }
  }
  disconnect() {
    App.locationNotifications = null;
  }

  updateData(data) {
    const element = document.getElementById('map');
    element.dataset.locationPayload = data
    const event = new CustomEvent('maps-update-location', {bubbles: true, cancelable: true});
    element.dispatchEvent(event);
    //alert("Location data has arrived!")
  }

  updateMarks(event) {
    event.preventDefault()
    const element = document.getElementById('map');
    element.dispatchEvent(new CustomEvent('maps-update-location', {bubbles: true, cancelable: true}));
  }

  addMarks(e) {
    window.markers.forEach((marker) => {marker.setMap(null);})
    window.circles.forEach((marker) => {marker.setMap(null);})
    window.markers = []
    window.circles = []
    this.infoWindow = new window.google.maps.InfoWindow
    if (!this.infoWindowPositioned) {
      window.map.controls[google.maps.ControlPosition.LEFT_TOP].push(legend);
      this.infoWindowPositioned = true
    }
    const element = e.currentTarget
    const ping = JSON.parse(element.dataset.locationPayload)
    const bounds = new google.maps.LatLngBounds();
    const colour = "#1568ce"
    this.updateInfoWindow(ping)
    ping.locations.forEach((loc, index) => {
      const latLng = new google.maps.LatLng(parseFloat(loc.actual.latitude), parseFloat(loc.actual.longitude))
      const marker = new google.maps.Marker({
        map: window.map,
        position:  latLng,
        label: (index + 1).to_string
      });
      window.markers.push(marker)

      const contentString = loc.display.info
      const infowindow = new google.maps.InfoWindow({
        content: contentString
      });
      marker.addListener('click', function() {
        infowindow.open(map, marker);
      });

      const circle = new google.maps.Circle({
        strokeColor: colour,
        strokeOpacity: 0.8,
        strokeWeight: 1,
        fillColor: colour,
        fillOpacity: 0.1,
        map: window.map,
        center: latLng,
        radius: parseInt(loc.actual.accuracy || 0)
      });
      bounds.union(circle.getBounds())
      window.circles.push(circle)
    });
    window.map.fitBounds(bounds, {right:200});
  }

  updateInfoWindow(ping){
    const contentString = ping.legend_content
    this.legend.innerHTML = contentString
  }

  zoomLocation(event){
    const element = event.currentTarget
    const location = element.dataset.location
    const marker = window.markers[element.dataset.index]
    this.infoWindow.close()
    this.infoWindow.setContent(location)
    this.infoWindow.open(window.map, marker);
    const circle = window.circles[element.dataset.index]
    window.map.fitBounds(circle.getBounds(), {right:200})
  }
}
