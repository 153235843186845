import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { 
    isChanged: Boolean,
    confirmMessage: { type: String, default: 'You have attempted to leave this page. If you have made any changes to the fields without clicking the Next button, your changes will be lost. Are you sure you want to exit this page?' }
   }

  formIsChanged(event) {
    this.isChangedValue = true
  }

  resetState(event) {
    this.isChangedValue = false
  }
 
  leavingPage(event) {
    if (this.isChangedValue) {
      event.preventDefault()
      if (window.confirm(this.confirmMessageValue)) { 
        event.detail.resume()
      }
    } 
  }
}
