import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {

    $(this.element).popup({
      inline: true,
      delay: {
        show: 0,
        hide: 1500,
      },
    });
    
  }
}
