import { Controller } from "@hotwired/stimulus";
import DateHelper from "../timeAgoInWords";

export default class extends Controller {
  static targets = ["respondedAgo"];
  static values = {
    createdAt: String,
    twentyFourHourTime: Boolean,
  };

  connect() {
    this.updateRespondedAgo();
  }

  updateRespondedAgo() {
    const date = new Date();
    const createdAt = this.createdAtValue;
    date.setTime(Date.parse(createdAt));

    const month = this.localMonthNames[date.getMonth()];

    let dateString = `${month} ${date.getDate()}, ${date.getFullYear()} <small class='time-bullet'>&#x25CF;</small> `;
    if (this.twentyFourHourTimeValue) {
      dateString += `${date.getHours()}:${date.getMinutes()}`;
    } else {
      dateString += `${date.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })}`;
    }

    this.respondedAgoTarget.innerHTML = dateString;
  }

  get localMonthNames() {
    let d = new Date(2000, 0); // January
    let months = [];
    for (let i = 0; i < 12; i++) {
      months.push(d.toLocaleString("default", { month: "short" }));
      d.setMonth(i + 1);
    }
    return months;
  }
}
