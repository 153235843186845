import {
  Controller
} from "@hotwired/stimulus"

export default class extends Controller {
  connect() {}

  validateForm(event) {
    this.removeErrors()
    if (!this.isValid) {
      event.preventDefault()
      event.stopPropagation()
      event.stopImmediatePropagation()
      this.addErrors()
    }
  }

  addErrors() {
    this.invalidFields.forEach((field) => {
      const wrapper = field.closest('div.form-group')
      wrapper.classList.add('has-error')
      const error = document.createElement("p");
      error.className = "error"
      error.innerText = "can't be blank"
      wrapper.appendChild(error)
    })
  }

  removeErrors(){
    Array.from(this.element.querySelectorAll('.has-error')).forEach(e => e.classList.remove('has-error'))
    Array.from(this.element.querySelectorAll('.error')).forEach(e => e.innerHTML = '')
  }

  get isValid() {
    return this.invalidFields.length == 0
  }

  get invalidFields() {
    return this.requiredFields.filter(field => field.value.length == 0)
  }

  get requiredFields() {
    return Array.from(this.element.querySelectorAll('input.required, textarea.required'))
  }
}