import { Controller } from "@hotwired/stimulus"


export default class extends Controller {

  connect() {
    const organization = this.element.dataset.organization
    const channel = `polls-${organization}`
    const showUnread = this.showUnread
    const tab = this.tab

    if (App[channel] == undefined)
    App[channel] = App.cable.subscriptions.create({channel: "PollInstanceChannel", room: organization}, {
      connected() {},
      // Called when the subscription is ready for use on the server

      disconnected() {},
      // Called when the subscription has been terminated by the server

      received(data) {
        $('#polls-none-open').remove()
        const $data = $(data)
        const id = $data.attr("id")
        if ($(`#${id}`).length == 0) {
          $data.prependTo("#polls-tab")
        } else{
          $(`#${id}`).replaceWith( $data )
        }
        showUnread(tab)
      }
    }
    );
  }

  removeUnread() {
    this.tab.classList.remove('unread')
  }

  get tab(){
    const tabName = this.element.dataset.tab
    return document.querySelector(`.tabs-dashboard [data-tab="${tabName}"]`)
  }

  showUnread(tab){
    if (!tab.classList.contains('active')) {
      tab.classList.add("unread")
    }
  }

}

