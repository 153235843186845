import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = { icon: String }

  notify(event) {
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      this.showNotification(event.detail);
    } else if (Notification.permission !== 'denied') {
      console.log('notify denied')
    }
  }

  showNotification(detail) {
    const notification = new Notification("Connect Rocket", {body: detail, icon: this.iconValue});
    notification.onclick = () => {
      window.focus()
    }
  }

  async requestPermission() {
    if (window.Notification) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Permission granted")
        } else {
          console.log("Permission denied")
        }
      })
    } else {
      console.log("Notifications are not supported")
    }
  }
}
