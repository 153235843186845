import { Controller } from "@hotwired/stimulus";


export default class extends Controller {
  static values = { signedUrl: String }
  static targets = [ "fileInput" ]
  static outlets = [ "channels" ]

  connect() {
    
  }
  
  async uploadFiles(event) {
    const files = Array.from(event.target.files)
    files.forEach(file => {
      this._uploadFile(file)
    });
    event.target.value = null
  }
    

  async _uploadFile(file) {
    const authToken = this.authTokenValue
    const organizationId = this.organizationIdValue
    const currentChannelId = this.currentChannelIdValue
    const timestamp = new Date().getTime()
    const key = `channelItems/${currentChannelId}/${timestamp}/${encodeURIComponent(file.name)}`

    this.fileInputTarget.classList.add("in-progress")

    const request = await fetch(this.signedUrlValue, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": authToken
      },
      body: JSON.stringify({ key: key, contentType: file.type }),
  })
    const response = await request.json()
    const signedUrl = response.uploadURL

    const upload = await fetch(signedUrl, {
      method: "PUT",
      headers: {
        "Content-Type": file.type,
        "Content-Length": file.size
      },
      body: file
    })
    this.fileInputTarget.classList.remove("in-progress")
    this.dispatch("itemUploaded", {detail: { file: file, key: key }} )
  }

  get authTokenValue() {
    return this.channelsOutlet.authTokenValue;
  }

  get currentChannelIdValue() {
    return this.channelsOutlet.currentChannelIdValue;
  }

  get organizationIdValue() {
    return this.channelsOutlet.organizationIdValue;
  }
}
