const DateHelper = {
  // Takes the format of "Jan 15, 2007 15:45:00 GMT" and converts it to a relative time
  // Ruby strftime: %b %d, %Y %H:%M:%S GMT
  time_ago_in_words_with_parsing: function(from) {
    const date = new Date;
    date.setTime(Date.parse(from));
    return this.time_ago_in_words(date);
  },
  // Takes a timestamp and converts it to a relative time
  // DateHelper.time_ago_in_words(1331079503000)
  time_ago_in_words: function(from) {
    return this.distance_of_time_in_words(new Date, from);
  },

  future: function(from) {
    const date = new Date;
    date.setTime(Date.parse(from));
    return date > new Date;
  },

  minutes_ago: function(from) {
    const to = new Date;
    const distance_in_seconds = ((to - Date.parse(from)) / 1000);
    return Math.floor(distance_in_seconds / 60);
  },

  distance_of_time_in_words: function(to, from) {
    const distance_in_seconds = ((to - from) / 1000);
    let distance_in_minutes = Math.floor(distance_in_seconds / 60);
    const pastTense = ""
    //const pastTense = distance_in_seconds < 0 ? "" : " ago";
    //const futureTense = distance_in_seconds > 0 ? "in " : "";
    distance_in_minutes = Math.abs(distance_in_minutes);
    if (distance_in_minutes == 0) { return 'less than a minute'+pastTense; }
    if (distance_in_minutes == 1) { return 'a minute'+pastTense; }
    if (distance_in_minutes < 45) { return distance_in_minutes + ' minutes'+pastTense; }
    if (distance_in_minutes < 90) { return 'about an hour'+pastTense; }
    if (distance_in_minutes < 1440) { return 'about ' + Math.floor(distance_in_minutes / 60) + ' hours'+pastTense; }
    if (distance_in_minutes < 2880) { return 'a day'+pastTense; }
    if (distance_in_minutes < 43200) { return Math.floor(distance_in_minutes / 1440) + ' days'+pastTense; }
    if (distance_in_minutes < 86400) { return 'about a month'+pastTense; }
    if (distance_in_minutes < 525960) { return Math.floor(distance_in_minutes / 43200) + ' months'+pastTense; }
    if (distance_in_minutes < 1051199) { return 'about a year'+pastTense; }

    return `over ${Math.floor(distance_in_minutes / 525960)} years`;
  }
};

export default DateHelper
