import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js";

export default class extends Controller {
  static values = {
    path: String,
    method: { type: String, default: "POST" },
    responseKind: { type: String, default: 'json' },
    payload: Object,
  };

  connect() {
    $(this.element).checkbox();
  }

  payload() {
    if (Object.keys(this.payloadValue).length) {
      const payload = this.payloadValue;
      payload.checked = this.element.checked;
      return payload;
    } else {
      const formData = new FormData();
      formData.append(this.element.name, this.element.checked ? '1' : '0');
      return formData;
    }
  }

  submit() {
    const payload = this.payload();
    
    if (this.hasPathValue) {
      const request = new FetchRequest(this.methodValue, this.pathValue, {
        body: payload,
        responseKind: this.responseKindValue,
      });
      request.perform(); // fire and forget, no need to await a response.
    }
  }
}
