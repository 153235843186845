import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    key: String,
    contentType: String,
    signedUrl: String,
    authToken: String,
    loading: Boolean
  };

  connect() {}

  async fetchItem(event) {
    event.preventDefault();
    if (this.loadingValue) return;
    this.loadingValue = true;
    const textContent = this.element.textContent;
    this.element.textContent = "Loading...";
    const request = await fetch(this.signedUrlValue, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: this.authTokenValue,
      },
      body: JSON.stringify({
        key: this.keyValue,
        contentType: this.contentTypeValue,
        operation: "getObject",
      }),
    });
    const response = await request.json();
    const signedUrl = response.uploadURL;
    this.element.textContent = textContent
    this.loadingValue = false;
    Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: signedUrl,
    }).click();
  }
}
