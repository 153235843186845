import { Controller } from "@hotwired/stimulus"


export default class extends Controller {

  static values = {
    authorId: String,
    createdAt: String,
    chatItemId: String,
  }

  connect() {
    this.determineSameAuthor()
  }

  get prevChannelItem() {
    const previous = this.element.previousElementSibling
    if (previous && previous.classList.contains('chat-item')) {
      return previous
    } else {
      return null
    }
  }

  determineSameAuthor(){
    const previous = this.prevChannelItem
    if(previous && previous.dataset.channelItemAuthorIdValue === this.authorIdValue){
      this.element.classList.add('same-author')
    }
  }

  deleteItem(){
    const event = new CustomEvent('deleteChannelItem', {bubbles: true, cancelable: true, detail: {
      createdAt: this.createdAtValue,
      id: this.chatItemIdValue,
      authorId: this.authorIdValue
    }})
    this.element.dispatchEvent(event);
  }
}