import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['actions']
  connect() {
  }

  toggle() {
    this.actionsTarget.classList.toggle("show")
  }

  close(event) {
    if (event.target !== this.element) {
      this.actionsTarget.classList.remove("show")
    }
  }
}
