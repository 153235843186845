import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    unreadCount: Number,
    channelId: String,
    currentChannel: Boolean
  }

  static targets = ["unreadCounter"]

  connect() {
  }

  disconnect(){
    
  }

  openedExternally(){
    const selector = document.getElementById("channels-semantic-ui-selector")
    if (selector) {
      $(selector).dropdown('set exactly', this.channelIdValue)
    }
  }

  currentChannel(event){
    this.currentChannelValue = this.element === event.detail
  }

  opened(){
    this.unreadCountValue = 0
    this.dispatch('channelOpened', {detail: this.element})
  }

  receiveChannelItem(event){
    const channelId = event.detail.channelId
    if (!this.currentChannelValue && channelId === this.channelIdValue) {
      this.unreadCountValue += 1
    }
  }

  unreadCountValueChanged(value) {
    if (value === 0) {
      this.unreadCounterTarget.classList.add('no-show')
    } else {
      this.unreadCounterTarget.classList.remove('no-show')
      this.unreadCounterTarget.textContent = value
    }
  }

}