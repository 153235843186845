import { BridgeComponent } from "@hotwired/strada";

export default class extends BridgeComponent {

  static component = "dtmf";
  static values = { key: String };

  sendTone(event) {
    event.preventDefault()
    console.log('send tone: ', this.keyValue)
    this.send('DtmfTone', { key: this.keyValue }, () => {
      console.log('DTMF tone sent')
    })
  }
}
