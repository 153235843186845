import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["active", "acceptsTextToVoiceCalls", "receivesCalls"]
  connect() {
    if (!this.activeTarget.checked) {
      this.disableOptions()
    }
  }

  updateStatus(event) {
    const changed = event.target
    if (changed === this.activeTarget && !changed.checked) {
      this.disableOptions()
    }else{
      this.enableOptions()
    }
  }

  disableOptions() {
    this.acceptsTextToVoiceCallsTarget.setAttribute("disabled", "disabled")
    if (this.hasReceivesCallsTarget) this.receivesCallsTarget.setAttribute("disabled", "disabled")
  }

  enableOptions() {
    this.acceptsTextToVoiceCallsTarget.removeAttribute("disabled")
    if (this.hasReceivesCallsTarget) this.receivesCallsTarget.removeAttribute("disabled")
  }

}
