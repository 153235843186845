import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    
  }

  select(event) {
    const tagName = event.currentTarget.tagName
    const selectedElements = this.element.querySelector(`${tagName}.selected`)
    selectedElements.forEach((element) => {
      element.classList.remove('selected');
    });
    $(event.currentTarget || event.target).addClass('selected')
  }
}
