import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js";

export default class extends Controller {

  static values = {
    url: String,
    toggle: String
  }

  connect() {
  }

  toggleSetting() {
    const payload = { toggle: this.toggleValue }
    const request = patch(this.urlValue, {
      body: payload,
    });
    request.perform(); // fire and forget, no need to await a response.
  }
}