import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
  }

  scrolling() {
    if (this.active && this.atBottom && !this.loadingMore) {
      this.loadMore()
    }
  }

  loadContent() {
    if (this.isContentEmpty && !this.loadingMore) {
      this.fetch()
      const cabled = this.element.querySelector('.cabled-in');
      if (cabled) {
        cabled.parentNode.removeChild(cabled);
      }
    }
  }

  fetch() {
    this.loadingMore = 'yes'
    const path = this.element.dataset.path
    const url = `${path}?page=${this.pageNumber}`
    return $.ajax({
      url: url,
      type: "GET",
      dataType: 'script'
    })
  }

  loadMore() {
    if (this.isContentLoaded) {
      this.pageNumber++
    }
    this.fetch()
  }

  get atBottom() {
    const body = document.body
    const html = document.documentElement
    const height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
    return (window.innerHeight + Math.ceil(window.pageYOffset)) >= height
  }

  get loadingMore(){
    return this.data.get('loadingMore')
  }

  set loadingMore(value){
    this.data.set('loadingMore', value)
  }

  get isContentLoaded() {
    return document.querySelector(this.data.get('emptySelector')) != null
  }

  get isContentEmpty(){
    return !this.isContentLoaded
  }

  get pageNumber(){
    return parseInt(this.data.get('pageNumber') || '1')
  }

  set pageNumber(value){
    this.data.set('pageNumber', value)
  }

  get active() {
    return this.element.classList.contains('active')
  }

}
