import { BridgeComponent } from "@hotwired/strada";

export default class extends BridgeComponent {
  static component = "events";
  static values = { key: String, preventDefault: Boolean };

  bridgeEvent(event) {
    if (this.preventDefaultValue) {
      event.preventDefault();
    }
    const key = this.keyValue;
    this.send("Event", { key: key }, () => {
      console.log(`Event ${key} sent`);
    });
  }
}
