/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createChannel = /* GraphQL */ `
  mutation CreateChannel($input: CreateChannelInput!) {
    createChannel(input: $input) {
      createdAt
      id
      name
      organizationId
    }
  }
`;
export const createChannelItem = /* GraphQL */ `
  mutation CreateChannelItem($input: CreateChannelItemInput!) {
    createChannelItem(input: $input) {
      author {
        avatar
        id
        initial
        name
      }
      channelId
      chatImage2 {
        key
        fileName
        contentType
        extension
        heightPixels
        widthPixels
      }
      chatImage {
        url
        version
        extension
        publicId
        heightPixels
        widthPixels
        fileName
      }
      chatAttachment {
        url
        publicId
        fileName
        extension
      }
      chatAttachment2 {
        key
        fileName
        extension
        contentType
      }
      chatVideo {
        url
        publicId
        fileName
        extension
      }
      chatMessage {
        text
      }
      createdAt
      id
      memberId
      updatedAt
      deletedAt
      replacementRecord
    }
  }
`;
export const readNotifications = /* GraphQL */ `
  mutation ReadNotifications($input: ReadNotificationInput!) {
    readNotifications(input: $input) {
      contactId
      createdAt
      images {
        fileName
        path
        previewPath
      }
      message
      notificationId
      notificationSk
      organizationId
      poll {
        answerId
        closesAt
        id
        question
      }
      readAt
      receivedAt
      recordingUrl
      sender {
        avatarUrl
        initial
        type
      }
      senderName
    }
  }
`;
export const updateChannelItem = /* GraphQL */ `
  mutation UpdateChannelItem($input: CreateChannelItemInput!) {
    updateChannelItem(input: $input) {
      author {
        avatar
        id
        initial
        name
      }
      channelId
      chatAttachment {
        url
        publicId
      }
      chatMessage {
        text
      }
      chatNotification {
        id
      }
      createdAt
      id
      memberId
      updatedAt
    }
  }
`;
export const deleteChannelItem = /* GraphQL */ `
  mutation DeleteChannelItem($input: DeleteChannelItemInput!) {
    deleteChannelItem(input: $input) {
      channelId
      createdAt
      memberId
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification($input: UpdateNotificationInput!) {
    updateNotification(input: $input) {
      contactId
      createdAt
      images {
        fileName
        path
        previewPath
      }
      message
      notificationId
      notificationSk
      organizationId
      poll {
        answerId
        closesAt
        id
        question
      }
      readAt
      receivedAt
      recordingUrl
      sender {
        avatarUrl
        initial
        type
      }
      senderName
    }
  }
`;
