import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['modal']
  connect() {
  }

  modalOrPage(event){
    const modal = document.getElementById(this.data.get('modal'))
    // debugger
    if (modal) {
      event.preventDefault()
      const openUp = new CustomEvent('openUp', {bubbles: true, cancelable: true});
      modal.dispatchEvent(openUp)
    }
  }
}
