import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.setActiveLinks()
  }

  setActiveLinks(){
    const currentPaths = window.location.pathname.split('/')
    currentPaths.forEach(currentPath => {
      const currentLink = this.element.querySelector(`a[href$='${currentPath}'], a[data-nested-paths*='${currentPath}']`)
      if (currentLink) {
        Array.from(this.element.querySelectorAll('li.active')).forEach( e => e.classList.remove('active'))
        currentLink.closest('li').classList.add('active')
      }
    })
  }
}
