import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['dashboard', 'submit']

  initialize(){
    this.getStats()
  }

  getStats(){
    this.submitTarget.click()
  }

  loadStats(event){
    let [data, status, xhr] = event.detail;
    this.dashboardTarget.innerHTML = xhr.response;
  }
}
