import { Controller } from "@hotwired/stimulus"
import loadMembers from "../loadMembers"

const waiting = "<div class='spinner'><div class='double-bounce1'></div><div class='double-bounce2'></div></div>"
const tabletWidth = 1024

export default class extends Controller {
  static targets = [ "input", "list", "memberCount", "groupCount", "groupCard", "groupList" ]

  initialize(){
    this.loadCard()
  }

  connect() {
    this.searchMembers()
    this.searchTimeout = null
  }

  search(event){
    const element = this.inputTarget
    const copy = element.value
    const list = $(this.groupListTarget)
    const listItems = list.children('li')
    if (copy) {
      listItems.hide()
      listItems.each((index, element) => {
        if (element.textContent.toLowerCase().includes(copy.toLowerCase())) {
          $(element).show()
        }
      })
    }else{
      listItems.show()
    }
  }

  searchMembers(){
    if (this.hasListTarget) {
      const $memberList = $(this.listTarget)
      const appendMember = this.appendMember
      this.pageNumber = 1
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout( () =>  loadMembers(this.inputTarget, this.pageNumber).done(data => {
        $memberList.empty()
        $.each(data, function( index, value ) {
          appendMember($memberList, value)
        });
      }), 350)
    }
  }

  loadCard(){
    if (this.hasGroupCardTarget) {
      const listItem = document.querySelector('.group-list-item a')
      if (listItem && this.desktop()) {
        listItem.click()
      }
    }
  }

  desktop(){
    return window.innerWidth >= tabletWidth
  }

  appendMember($memberList, value){
    $memberList.append(value.html_tag)
  }

  updateListMembership(event) {
    const $this = $(event.target)
    this.updateStats($this)
  }

  openGroup(event) {
    this.groupCardTarget.removeAll()
    this.groupCardTarget.innerHTML = waiting

    $('.organization_groups_list').find('li').removeClass('selected')
    $(event.currentTarget || event.target).addClass('selected')
  }

  updateStats($element){
    if ($element.attr('id').includes('member')) {
      this.updateMemberStats($element)
    }else{
      this.updateGroupStats($element)
    }
  }

  updateMemberStats($element) {
    const count = parseInt($(this.memberCountTarget).text())
    if ($element.is(":checked")) {
      $(this.memberCountTarget).text(count + 1)
    }else{
      $(this.memberCountTarget).text(count - 1)
    }
  }

  updateGroupStats($element) {
    const count = parseInt($(this.groupCountTarget).text())
    if ($element.is(":checked")) {
      $(this.groupCountTarget).text(count + 1)
    }else{
      $(this.groupCountTarget).text(count - 1)
    }
  }

  openDestroyModal(event){
    event.preventDefault()
    $('#delete-group-modal').modal('show')
  }

  get pageNumber(){
    return parseInt(this.data.get('pageNumber') || '1')
  }
  set pageNumber(value){
    this.data.set('pageNumber', value)
  }

  get loadingMore(){
    return this.data.get('loadingMore')
  }
  set loadingMore(value){
    this.data.set('loadingMore', value)
  }

  loadMore(){
    if (this.loadingMore == true) {
      return
    }
    const $memberList = $(this.listTarget)
    const appendMember = this.appendMember
    this.loadingMore = true
    this.pageNumber++
    loadMembers(this.inputTarget, this.pageNumber).done(data => {
      this.loadingMore = false
      $.each(data, function( index, value ) {
        appendMember($memberList, value)
      });
    })
  }
  infiniteScroll(event) {
    const element = event.currentTarget
    if (element.scrollHeight - Math.ceil(element.scrollTop) <= element.clientHeight) {
      this.loadMore()
    }
  }
}
