import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js";

export default class extends Controller {
  static values = { url: String }
  connect() {
    console.log('nested form controller connected')
  }

  submitForm(event) {
    event.preventDefault()
    event.stopPropagation()
    const fields = this.element.querySelectorAll('input, textarea')
    const formData = new FormData()
    fields.forEach(field => {
      formData.append(field.name, field.value)
    })
    const request = new FetchRequest('POST', this.urlValue, {
      headers: { 'Accept': 'text/vnd.turbo-stream.html' },
      body: formData,
    });
    request.perform(); // fire and forget, no need to await a response.
  }
}
