import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "submit" ]
  static values = { fieldId: String }

  submitForm(event){
    const formSelector = event.target.dataset.formSelector
    if (event.target.dataset.disableWith) {
      event.target.innerText = event.target.dataset.disableWith
    }
    if (formSelector) {
      this.submitTargets.find( e => e.closest(formSelector) ).click()
    } else {
      this.submitTarget.click()
    }
  }

  submitOnChange(event){
    const fieldId = this.fieldIdValue
    const triggeringId = event.target.id
    console.log(fieldId, triggeringId)
    if (this.hasFieldIdValue && fieldId === triggeringId) {
      this.element.closest('form').requestSubmit()
    }
  }
}
