import {
  Controller
} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "submit" ]

  connect() {
    this.validateForm()
  }

  validateForm() {
    if (this.isValid) {
      this.enableSubmit()
    } else {
      this.disableSubmit()
    }
  }

  disableSubmit() {
    this.submitTarget.disabled = true
  }

  enableSubmit() {
    this.submitTarget.disabled = false
  }
  
  get isValid() {
    return this.selectedFields.length > 0
  }

  get selectedFields() {
    return Array.from(this.element.querySelectorAll('input[type="checkbox"]:checked'))
  }

}