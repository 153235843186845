import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["preambleText", "preamble"]
  connect() {
    this.assignPreamble()
  }

  assignPreamble(){
    this.preambleTarget.innerHTML = this.buildPreamble()
  }

  buildPreamble(){
    const preambleText = this.preambleTextTarget.value
    const defaultText = `<span class="spacer">aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</span>`
    const groupNames = this.data.get("groupNames")
    const notificationSignature = this.data.get("notificationSignature").length > 0 ? this.data.get("notificationSignature") : '- your signature'
    let str = preambleText
    if (this.includeGroupNames) {
      str += ` ${groupNames}`
    }
    if (str.length > 0) {
      str += ": "
    }
    str += defaultText
    if (this.includeSignatureField) {
      str += ` ${notificationSignature}`
    }
    return `<p>${str}</p>`
  }

  get includeGroupNames(){
    return document.getElementById("organization_include_list_names_in_text_message_preamble").checked
  }

  get includeSignatureField(){
    const includeSignatureField = document.getElementById("organization_include_notification_signature")
    return includeSignatureField && includeSignatureField.checked
  }

  get hasPreambleText(){
   return this.preambleTextTarget.value.length > 0
  }

}
