import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js";
import Sortable from 'sortablejs';

export default class extends Controller {
  connect() {
    new Sortable(this.element, {
      handle: '.drag-handle',
      onEnd: () => this.sendPositions()
    });
  }

  sendPositions() {
    const path = this.data.get('path')
    const list = Array.from(this.element.querySelectorAll('[data-position]')).map(e => e.id)
    if (path) {
      const request = new FetchRequest('POST', path, {
        body: {sortOrder: list},
      });
      request.perform(); // fire and forget, no need to await a response.
    }
  }
}