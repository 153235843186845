import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
  }
  closeForms(event){
    const target = event.target
    const otherForms = this.adminForms.filter(c => c.element != target)
    otherForms.forEach(c => c.closeForm())
  }

  get adminForms() {
    const forms = this.application.controllers.filter(controller => controller.identifier === 'admin-edit');
    return forms
  }
}