import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "message", "submit" ]

  connect() {
    this.validate()
  }

  validate(){
    
    const messageLength = this.hasMessageTarget ? this.messageTarget.value.trim().length : 0
    if (messageLength == 0) {
      this.disableSubmit()
    }else{
      this.enableSubmit()
    }
  }

  disableSubmit() {
    this.submitTarget.classList.add('disabled');
  }

  enableSubmit() {
    this.submitTarget.classList.remove('disabled');
  }

}
