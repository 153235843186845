import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'sendAgain',
    'messageField',
    'messageTab',
    'locationTab'
  ]
  static values = {
    prioritzeLocate: Boolean
  }

  connect() {
    this.displaySendAgain()
    this.setInitialTabs()
    this.focusInputIfCurrentRequest()
  }

  displaySendAgain() {
    const expires = Date.parse(this.data.get("expires"))
    
    if (expires > new Date) {
      this.sendAgainTarget.classList.remove('no-show')
    }
  }

  setInitialTabs() {
    const tabs = this.prioritzeLocateValue ? this.locationTabTargets : this.messageTabTargets
    tabs.forEach(t => {
      t.classList.add('active')
    });
    this.removeUnread()
  }

  hideButton() {
    const message = this.data.get('disabled')
    this.sendAgainTarget.disabled = true
    this.sendAgainTarget.value = message
  }

  removeUnread() {
    const unRead = this.element.querySelector('.item.unread')
    if (unRead) {
      unRead.classList.remove('unread')
    }
  }

  focusInputIfCurrentRequest() {
    if (this.hasMessageFieldTarget && this.firstRequest != undefined && this.firstRequest.id == this.element.id) {
      this.messageFieldTarget.focus()
    }
  }

  get firstRequest() {
    return this.element.parentElement.querySelector('.location-request')
  }
}
