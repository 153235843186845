import { Controller } from "@hotwired/stimulus"
import * as Cookies from "js-cookie";

const waitingHtml = "<div class='spinner'><div class='double-bounce1'></div><div class='double-bounce2'></div></div>";

export default class extends Controller {

  openModal(event) {
    event.preventDefault()
    const id = event.currentTarget.dataset.modalId
    this._openModal(id)
  }

  openUp(event){
    const id = event.target.id
    this._openModal(id)
  }

  // Open Modals
  _openModal(themodalId){
    const themodal = document.getElementById(themodalId)
    themodal.style.display = 'inline';
    themodal.classList.add('is-open');
    const waiting = themodal.getElementsByClassName('waiting')
    if (waiting.length) {
      waiting[0].innerHTML = waitingHtml
    }
    this.dimmer.style.display = 'inline';
    document.body.classList.add('is-fixed');
    const event = new CustomEvent('modalOpen', {bubbles: true, cancelable: true});
    themodal.dispatchEvent(event);
    // 
  }

  goBack(){
    this.closeModal()
    this.fireGoBackward()
  }

  goForward(next){
    this.setHistory()
    this.closeModal()
    const next_modal_identifier = typeof next === 'string' ? next : this.data.get('next')
    //debugger
    if (next_modal_identifier) {
      this._openModal(next_modal_identifier)
    }
  }

  closeModalFromDimmer(){
    if (this.currentModal.classList.contains('locked')) {
      return
    } else {
      this.closeModal()
      this.resetHistory()
    }
  }
  // Close Modals
  closeModal(){
    this.dimmer.style.display = 'none';
    document.body.classList.remove('is-fixed');
    if (this.currentModal) {
      this.currentModal.querySelectorAll('audio').forEach(audio => audio.pause())
      this.currentModal.style.display = 'none';
      this.currentModal.classList.remove('is-open');
    }
  }

  setHistory() {
    this.dispatchCustomEvent('setHistory')
  }

  fireGoBackward() {
    this.dispatchCustomEvent('goBackward')
  }

  resetHistory() {
    this.dispatchCustomEvent('resetHistory')
  }

  dispatchCustomEvent(eventName) {
    const event = new CustomEvent(eventName, {bubbles: true, cancelable: true});
    this.element.dispatchEvent(event);
  }

  get dimmer() {
    return document.getElementById('dimmer')
  }

  get currentModal(){
    return document.querySelector('.modal.is-open')
  }

}
