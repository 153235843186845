import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "duty", "status", "statusLabel", "nextDutyChange" ]
  static values = { id: String }

  connect() {
    this.updateStatusLabel()
  }

  toggleStatus() {
    this.updateStatusLabel()
    if (this.hasNextDutyChangeTarget) {
      this.nextDutyChangeTarget.classList.add('no-show')
    }
  }

  updateStatusLabel() {
    if (this.hasStatusLabelTarget) {
      const label = this.statusLabelTarget
      label.innerHTML = this.statusTarget.checked == true ? label.dataset.activateTerm : label.dataset.deactivateTerm
    }
  }

  openDestroyModal(event){
    event.preventDefault()
    $('#delete-contact-modal').modal('show')
  }
}
