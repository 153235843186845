import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['play', 'pause', 'audio']
  connect() {
  }

  play(event){
    event.stopPropagation()
    event.preventDefault()
    this._hide(this.playTarget)
    this._show(this.pauseTarget)
    this.audioTarget.play()
  }

  pause(event){
    event.stopPropagation()
    event.preventDefault()
    this.revertToPlay()
    this.audioTarget.pause()
  }

  revertToPlay(){
    this._show(this.playTarget)
    this._hide(this.pauseTarget)
  }

  _hide(element){
    element.classList.add('no-show')
  }

  _show(element){
    element.classList.remove('no-show')
  }
}
