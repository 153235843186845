import { Controller } from "@hotwired/stimulus";
import AWS from 'aws-sdk/global';
import S3 from 'aws-sdk/clients/s3';

const uuidv4 = require('uuid/v4');

export default class extends Controller {
  static targets = [ "scope", "status", "player", 'audioUrl', 'fileField', 'progressIndicator', 'progressLine']
  initialize(){
    const aws_pub = process.env.AWS_S3_ACCESS_KEY_ID
    const aws_secret = process.env.AWS_S3_SECRET_KEY
    AWS.config.update({
      region: 'us-east-1',
      credentials: new AWS.Credentials(aws_pub, aws_secret)
    });
  }

  connect(){
  }

  upload(event){
    const file = event.target.files[0]
    const name = uuidv4() + '-' + encodeURIComponent(file.name.split(" ").join('-'))
    this.statusTarget.innerText = "Uploading"
    this.disableSubmit()
    this._uploadS3(file, name, event.target)
  }

  uploadComplete(event){
    this.statusTarget.innerText = ""
    this.statusTarget.innerText = "Processing audio file, this may take several seconds"
    this.progressIndicatorTarget.classList.add('no-show')
    const pathName = event.detail
    const bucketUrl = "https://s3.amazonaws.com/connect-rocket-audio/"
    const filename = pathName.split('.')[0]
    const extension = pathName.split('.')[1]
    const mp3Url = bucketUrl + filename + ".mp3"
    this.checkForProcessedMp3File(mp3Url)
  }

  checkForProcessedMp3File(mp3Url, tryAgain = this.checkForProcessedMp3File, audioUrl = this.audioUrlTarget, fileField = this.fileFieldTarget){
    const xhr = new XMLHttpRequest();
    xhr.open('HEAD', mp3Url, true);
    xhr.onreadystatechange = function(e) {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          audioUrl.value = mp3Url
          if (fileField) {
            fileField.value = ''
          }
          const change = new CustomEvent('change', {bubbles: true});
          audioUrl.dispatchEvent(change);
        } else {
          setTimeout(tryAgain, 500, mp3Url, tryAgain, audioUrl);
        }
      }
    }
    xhr.send();
  }

  loadPlayer() {
    const statusTarget = this.statusTarget
    this.statusTarget.innerText = ""
    const audioUrlTarget = this.audioUrlTarget
    const fileName = this.audioUrlTarget.value
    const players = this.playerTarget
    this.enableSubmit()
    const audioPlayer = document.createElement("audio");
    this.playerTarget.innerHTML = ''
    if (!audioUrlTarget.value) {
      return
    }
    audioPlayer.className = "audio-player";
    audioPlayer.src = audioUrlTarget.value
    audioPlayer.setAttribute("controls", "")
    audioPlayer.oncanplay = function() {
      statusTarget.innerText = '';
      players.appendChild(audioPlayer)
      const trash = document.createElement('span')
      trash.dataset.action = "click->audio-upload#removeAudio"
      trash.innerText = "Remove"
      trash.classList.add('remove-audio')
      players.appendChild(trash)
    }
  }

  disableSubmit() {
    this.isExistingButtonValid = !this._btn.classList.contains('disabled')
    this._btn.classList.add('disabled')
  }

  enableSubmit(){
    if (this.isExistingButtonValid) {
      this._btn.classList.remove('disabled')
    }
  }

  removeAudio(){
    this.playerTarget.innerHTML = ''
    this.audioUrlTarget.value = ''
    this.fileFieldTarget.value = ''
  }

  _uploadS3 (file, file_name, target) {
    const objKey = 'audio-recordings/'
    const pathName = objKey + file_name;
    const progressIndicator = this.progressIndicatorTarget
    const progressLine = this.progressLineTarget
    const s3params = {
      Bucket: 'connect-rocket-audio',
      signatureVersion: 'v4',
      Key: pathName,
      ContentType: file.type
    };
    const s3obj = new S3({params: s3params});
    s3obj.upload({Body: file}).on('httpUploadProgress', function(evt) {
      const percentage = Math.round(evt.loaded / evt.total * 100)
      progressIndicator.classList.remove('no-show')
      progressLine.style.width = `${percentage}%`
    }).send(function(err, data) {
      const event = new CustomEvent('s3UploadComplete', {bubbles: true, cancelable: true, detail: pathName})
      target.dispatchEvent(event);
    });
  }

  get _btn(){
    return document.querySelector('#notification-compose .ui.positive.right.button') || this.element.querySelector('.ui.positive.right.button') || this.element.querySelector("input[type='submit']") || this.element.querySelector("button[type='submit']")
  }
}
