import {
  Controller
} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form"]
  connect() {
    $('.tabular.menu .item').tab();
  }

  updateQuery(event) {
    if (event.target.type === 'text' && event.target.value.length < 4 && event.target.value.length != 0) {
      return
    }
    this.data.set("queryParams", $.param(this.formValues))
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    const updateEvent = new CustomEvent('paramsUpdated', {
      bubbles: true,
      cancelable: true
    });
    this.searchTimeout = setTimeout(() =>
      this.tableScrollElements.forEach(e => e.dispatchEvent(updateEvent)), 350)
  }

  get formValues() {
    const obj = {}
    Array.from(this.element.querySelectorAll('input, select'))
      .filter(e => e.value.length >= 4)
      .forEach(e => obj[e.name] = e.value)
    return obj
  }

  get tableScrollElements() {
    return Array.from(document.querySelectorAll('[data-controller="table-scroll"]'))
  }
}