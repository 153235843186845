import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["purgeMediaIds"]
  connect() {
    
  }

  async removeMedia(event) {
    event.stopPropagation()
    event.preventDefault()

    const mediaElement = event.target.closest('.media')
    mediaElement.classList.add('no-show')

    const mediaId = mediaElement.dataset.mediaId
    const hiddenField = document.createElement("input");
    hiddenField.setAttribute("type", "hidden");
    hiddenField.setAttribute("value", mediaId);
    hiddenField.name = this.purgeMediaIdsTarget.name;
    this.element.appendChild(hiddenField);
  }
}
