import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  toggleNumbers(event){
    event.stopPropagation()
    const link = event.target
    const list = link.closest('td').querySelector('ul.hidden')
    link.closest('ul').classList.add('hidden')
    list.classList.remove('hidden')
  }
}