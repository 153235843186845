import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
  }

  populate(event){
    event.preventDefault()
    const content = this.data.get('content')
    const target = document.getElementById('send-notification')
    const button = document.getElementById('dashboard-button-send-notification')
    target.dataset.notificationsContent = content
    button.click()
  }
}
