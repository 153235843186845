import { Controller } from "@hotwired/stimulus"
import DateHelper from "../timeAgoInWords"


export default class extends Controller {
  static targets = [ "closesIn" ]

  connect() {
    if (this.element.classList.contains('active')) {
      this.updateClosesAt()
      this.startRefreshingClosesAt()
    }
  }

  disconnect() {
    this.stopRefreshingClosesAt()
  }

  openPoll(event) {
    if (event.target.tagName.toLowerCase() != "a") {
      event.currentTarget.querySelector('a').click()
    }
  }

  startRefreshingClosesAt(){
    this.closesAtRefreshTimer = setInterval(() => {
      this.updateClosesAt()
    }, 10000)
  }

  updateClosesAt(){
    const closesAtString = DateHelper.future(this.data.get("closesAt"))? `Closes in ${DateHelper.time_ago_in_words_with_parsing(this.data.get("closesAt"))}` : "Closed"
    this.closesInTarget.innerHTML = closesAtString
  }

  stopRefreshingClosesAt() {
    if (this.closesAtRefreshTimer) {
      clearInterval(this.closesAtRefreshTimer)
    }
  }
}
