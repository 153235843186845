import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  connect() {
    this.resetHistory()
  }

  setHistory(event){
    this.history.push(event.target.id)
  }

  openPrevious(){
    const previousId = this.history.pop()
    if (previousId) {
      const previous = document.getElementById(previousId)
      const event = new CustomEvent('openUp', {bubbles: true, cancelable: true});
      previous.dispatchEvent(event);
    }
  }

  removeHistory(event){
    this.history.pop()
  }

  resetHistory(){
    this.history = []
  }

}
