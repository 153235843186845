import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
  }

  submitForm(event){
    this.form.requestSubmit()
  }

  toggleElement(event){
    const target = event.target
    if (target.dataset['adminToggleElementId']) {
      const element = document.getElementById(target.dataset['adminToggleElementId'])
      target.checked ? this._show(element) : this._hide(element)
    }
  }

  get form(){
    if (this.element.tagName == 'FORM') {
      return this.element
    } else {
      return this.element.parentElement.querySelector('form')
    }
  }

  _hide(element){
    element.classList.add('no-show')
  }

  _show(element){
    element.classList.remove('no-show')
  }
}
