
export default function (pageElement, pageNumber, scope) {
  const string = pageElement.value
  const $pageElement = $(pageElement)
  if ($pageElement.length == 0) {
    return
  }
  if (window.loadingContacts) {
    return
  }
  let page = pageNumber
  let url = `${$pageElement.data('membersPath')}?page=${page}`
  let list_id = $pageElement.data('list')
  let search = $pageElement.val() || ""
  if (list_id) {
    url += `&list_id=${list_id}`
  }
  if (search.length > 2) {
    url += `&search=${encodeURIComponent(search)}`
  }
  if (scope) {
    url += `&scope=${scope}`
  }
  window.loadingContacts = true
  return $.ajax({
    url : url,
    type: "GET",
    dataType: "json"
  }).always(
    window.loadingContacts = false
  )
}
