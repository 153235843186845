import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "activateBy", "inboundCallRecordingField", "alphanumericCodeField", "conferenceCallButton", "pagerNotificationButton", "pagerNotificationSection", "conferenceCallSection", "inboundNumber", "requiresRecording", "smsResponseField", "smsResponse", "inboundNumberId", "toggleHint", "actionSection", "noConferenceNumbersAvailable" ]
  connect() {
    this.toggle()
    this.checkForErrors()
    this.actionSectionTargets.forEach( e => this.updatePreview(e))
    this.detectIfCanConferenceCall()
  }

  toggle() {
    this.toggleButtons()
    this.updateRecordingOptions()
    this.toggleSmsResponse()
    this.removeThisConferenceCallNumber()
    this.toggleHints()
  }

  clickSection(event){
    if (!event.target.closest('.title')) {
      return
    }
    const section = event.target.closest('.action').querySelector('.content')
    if (event.target.closest('.action.current')) {
      this.closeElement(section)
      return
    }
    this.openElement(section)
    Array.from(document.querySelectorAll('#actions .action .content')).filter(element => element != section).forEach(e => this.closeElement(e))
  }

  openElement(element) {
    element.closest('.action').classList.add('current')
    $(element).slideDown()
  }

  closeElement(element){
    $(element).slideUp()
    element.closest('.action').classList.remove('current')
    this.updatePreview(element.closest('.action'))
  }

  checkForErrors(){
    const errors = this.element.querySelectorAll('.has-error')
    Array.from(errors).forEach( error => error.closest('.action').classList.add('contains-error') )
  }

  updatePreview(actionElement){
    const preview = actionElement.querySelector('.preview')
    if (!preview) {
      return
    }
    preview.innerHTML = ''
    const preview_text = actionElement.querySelector('textarea, input[type="text"]').value
    preview.insertAdjacentHTML('beforeend', `<p>${preview_text}`)
    const checkedBoxes = actionElement.querySelectorAll(".list_id:checked");
    const labels = Array.from(checkedBoxes).map(e => e.parentNode.textContent);
    if (labels.length) {
      let sentance = ''
      if (labels.length > 1) {
        sentance += labels.slice(0, labels.length - 1).join(', ') + ", and " + labels.slice(-1);
      } else {
        sentance += labels[0]
      }
      preview.insertAdjacentHTML('beforeend', `<p>Sent to ${sentance}</p>`)
    } else {
      preview.insertAdjacentHTML('beforeend', `<p>Sent to ALL CONTACTS</p>`)
    }
  }

  addedFields(){
    this.toggle()
  }

  toggleSmsResponse(){
    if (this.hasSmsResponseTarget) {
      const triggerBySms = this.smsResponseTarget.checked
      triggerBySms ? this.showSmsResponse() : this.hideSmsResponse()
    } else {
      this.hideSmsResponse()
    }
  }

  toggleHints(){
    this.toggleHintTargets.forEach(wrapper => {
    const checkbox = wrapper.querySelector('input[type="checkbox"]')
    const hint = wrapper.querySelector('.hint')
     if (checkbox.checked) {
       this._show(hint)
     } else {
       this._hide(hint)
     }
    });
  }

  showSmsResponse(){
    for (const element of this.smsResponseFieldTargets) {
      this._show(element)
    }
  }

  hideSmsResponse(){
    for (const element of this.smsResponseFieldTargets) {
      this._hide(element)
    }
  }

  updateRecordingOptions(){
    if (this.isActivateByInboundcallRecording) {
      for (const element of this.alphanumericCodeFieldTargets) {
        this._hide(element)
      }
      for (const element of this.inboundCallRecordingFieldTargets) {
        this._show(element)
      }
    } else {
      for (const element of this.alphanumericCodeFieldTargets) {
        this._show(element)
      }
      for (const element of this.inboundCallRecordingFieldTargets) {
        this._hide(element)
      }
    }
  }

  removeFields(event){
    const section = event.target.closest('div.action')
    this._hide(section)
    section.removeAttribute('data-workflow-admin-target')
    const destroy = section.querySelector('[name$="[_destroy]"]')
    if (destroy) {
      destroy.value = "1"
    }
    this.toggle()
  }

  toggleButtons() {
    const conferenceCallButton = this.conferenceCallButtonTarget
    const pageButton = this.pagerNotificationButtonTarget
    if (this.hasConferenceCallSectionTarget) {
      this._disable(conferenceCallButton)
      //this.disableInboundCallRecordingNumber(this.inboundNumberTarget.value)
    }else {
      this._enable(conferenceCallButton)
    }
    if (this.hasPagerNotificationSectionTarget) {
      this._disable(pageButton)
    }else{
      this._enable(pageButton)
    }
  }


  detectIfCanConferenceCall() {
    const conferenceCallButton = this.conferenceCallButtonTarget
    if (this.hasNoConferenceNumbersAvailableTarget) {
      this._disable(conferenceCallButton)
    }
  }
  removeThisConferenceCallNumber() {
    if (this.hasConferenceCallSectionTarget && this.isActivateByInboundcallRecording){
      const option = this.conferenceCallSectionTarget.querySelector(`option[value="${this.inboundNumberIdTarget.value}"]`)
      if (option) {
        option.remove()
      }
    } 
  }

  get activateBy() {
    if (this.hasRequiresRecordingTarget && this.requiresRecordingTarget.checked) {
      return "inbound_call_recording"
    } else {
      return this.activateByTarget.value
    }
  }

  get isConferenceCallVisible() {
    if (!this.hasConferenceCallSectionTarget) {
      return false
    }
    const conferenceCallSection = this.conferenceCallSectionTarget
    return this.isVisible(conferenceCallSection)
  }

  get isActivateByInboundcallRecording(){
    return this.activateBy === 'inbound_call_recording'
  }

  get isActivateByAlphanumericCode(){
    return this.activateBy === 'alphanumeric_code'
  }


  _hide(element){
    element.classList.add('hidden')
  }

  _show(element){
    element.classList.remove('hidden')
  }

  _disable(element){
    element.classList.add('disabled')
  }

  _enable(element){
    element.classList.remove('disabled')
  }

  isVisible(element){
    return !element.classList.contains('hidden')
  }
}
