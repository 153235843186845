// Entry point for the build script in your package.json

/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Polyfills for IE11 and Stimulus
import "core-js/fn/array/find";
import "core-js/fn/array/find-index";
import "core-js/fn/array/from";
import "core-js/fn/map";
import "core-js/fn/object/assign";
import "core-js/fn/promise";
import "core-js/fn/set";
import "element-closest";
import "mutation-observer-inner-html-shim";
import "custom-event-polyfill";
import "jquery-address";
import "whatwg-fetch";

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails";
import "@rails/activestorage";
import cloudinary from "cloudinary-core";
import { Application } from "@hotwired/stimulus";
import Flatpickr from "stimulus-flatpickr";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

Rails.start();

const application = Application.start();
// application.debug = true;
const context = require.context("../src/controllers", true, /\.js$/);
application.load(definitionsFromContext(context));
application.register("flatpickr", Flatpickr); // Manually register Flatpickr as a stimulus controller

require("jquery");

window.loadingContacts = false;
window.loadingLists = false;
window.$ = $;
window.jQuery = $;

$.fn.popup = require("semantic-ui-popup");
$.fn.dimmer = require("semantic-ui-dimmer");
$.fn.modal = require("semantic-ui-modal");
$.fn.transition = require("semantic-ui-transition");
$.fn.dropdown = require("semantic-ui-dropdown");
$.fn.checkbox = require("semantic-ui-checkbox");
$.fn.accordion = require("semantic-ui-accordion");
$.fn.tab = require("semantic-ui-tab");
$.fn.sticky = require("semantic-ui-sticky");

if (!Element.prototype.removeAll) {
  Element.prototype.removeAll = function () {
    while (this.firstChild) {
      this.removeChild(this.firstChild);
    }
    return this;
  };
}

Turbo.StreamActions.redirect = function() { 
  Turbo.visit(this.target)
}

// hide flash
$(document).on("turbo:load", function () {
  $(".flash.notice").delay(5500).slideUp();
  $(".flash.alert").delay(7500).slideUp();
});

window.setupPage = function () {
  $(".ui.checkbox").checkbox();
  $(".ui.modal").modal({
    observeChanges: true,
    transition: "fade",
    duration: 100,
  });
  $(".ui.dropdown").dropdown({
    onChange: function (value, text, $choice) {
      if ($choice && typeof $choice != "string") {
        var event = new CustomEvent("dropdownSelection", {
          bubbles: true,
          cancelable: true,
        });
        var element = $choice.get(0);
        element.dispatchEvent(event);
      }
    },
  });
  $(".tabs-dashboard .item").tab({
    history: true,
    historyType: "hash",
    onVisible: function (tabPath) {
      var event = new CustomEvent(`tab-open-${tabPath}`, {
        bubbles: true,
        cancelable: true,
      });
      $(`[data-tab='${tabPath}']`).each(function (index) {
        var element = this;
        element.dispatchEvent(event);
      });
    },
  });
  $(".group-tabs .item, .locate-tabs .item, .tabs-community-preview .item").tab({
    onVisible: function (tabPath) {
      var event = new CustomEvent(`tab-open`, {
        bubbles: true,
        cancelable: true,
      });
      $(`[data-tab='${tabPath}']`).each(function (index) {
        var element = this;
        element.dispatchEvent(event);
      });
    },
  });

  $(".ui.accordion").accordion();
};

document.addEventListener("ajax:complete", function () {
  window.setupPage();
});
document.addEventListener("turbo:load", function () {
  window.setupPage();
});
document.addEventListener("turbo:frame-load", function () {
  window.setupPage();
});

window.initMap = () => {
  const element = document.getElementById("map");
  window.google = google;
  const map = new google.maps.Map(element, {
    center: { lat: 0, lng: 0 },
    zoom: 1,
    zoomControl: true,
    scaleControl: true,
    mapTypeControl: true,
    mapTypeId: "terrain",
    mapTypeControlOptions: {
      mapTypeIds: ["satellite", "terrain"],
    },
  });
  window.map = map;
  window.markers = [];
  window.circles = [];
  if (element.dataset.locationPayload) {
    var event = new CustomEvent("maps-update-location", {
      bubbles: true,
      cancelable: true,
    });
    element.dispatchEvent(event);
  }
};

$(document).on("click", "form .delete-record", function (event) {
  $(this).prev("input[type=hidden]").val("1");
  $(this).closest("fieldset, tr").hide();
  return event.preventDefault();
});

$(document).on("click", "form .add_fields", function (event) {
  const time = new Date().getTime();
  const regexp = new RegExp($(this).data("id"), "g");
  $(this).before($(this).data("fields").replace(regexp, time));
  window.setupPage();
  const elements = $("fieldset .hidden[name$='[position]']");
  const lastPosition = elements.last();
  if (lastPosition && lastPosition.val() == "") {
    lastPosition.val(elements.length);
  }
  $(".focus-me").last().focus();
  return event.preventDefault();
});

$(document).ready(function () {
  // sticky
  $(".ui.sticky").sticky({
    context: ".side-col",
  });
});
