import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  openReport(event) {
    const sid = event.currentTarget.id
    event.preventDefault()
    this.getReport(sid)
    const openUp = new CustomEvent('openUp', {bubbles: true, cancelable: true});
    document.getElementById("activity-modal").dispatchEvent(openUp)
  }

  getReport(sid){
    const path = document.location.pathname
    return $.ajax({
      url: `${path}/${sid}`,
      type: "GET",
      dataType: 'script'
    })
  }
}