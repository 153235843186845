import {
  Controller
} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['name', 'phoneNumber', 'error', 'button']
  connect() {
    // debugger
  }

  validate(event) {
    if (!this.isValid) {
      event.preventDefault()
      this.setError("Please enter a valid phone number")
    } else {
      this.connectChannel()
      this.disableButton()
    }
  }

  get isValid() {
    return this.phoneNumberTarget.value.length > 9
  }

  setError(message) {
    this.errorTarget.innerHTML = message
    const group = this.errorTarget.closest('div.form-group')
    if (group) {
      group.classList.add('has-error')
    }
    const button = this.buttonTarget || this.button
    button.classList.remove('disabled')
    button.innerHTML = "Call Me"
  }

  get button() {
    return this.element.querySelector('.ui.positive.right.button')
  }

  callError() {
    this.setError("Please enter a valid Phone Number")
  }

  disableButton() {
    this.buttonTarget.classList.add('disabled')
    this.buttonTarget.innerHTML = "Calling"
  }

  connectChannel() {
    const phoneId = this.data.get("id")
    const channelId = `${phoneId}-greeting`

    if (App[channelId] == undefined) {
      App[channelId] = App.cable.subscriptions.create({
        channel: "InboundNumberChannel",
        room: channelId
      }, {
        connected() {},
        // Called when the subscription is ready for use on the server

        disconnected() {},
        // Called when the subscription has been terminated by the server

        received(data) {
          location.reload()
        }
      });
    }
  }

}