import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["message", "input"];
  static values = { 
    mediaInputId: String,
    audioInputId: String,
  };

  selectMessage(event) {
    if (!this.data.has("preamble")) {
      this.data.set("preamble", this.messageTarget.value);
      this.data.set("cursorPosition", this.messageTarget.selectionStart);
    }
    const data = event.currentTarget.dataset;
    const content = data.message || "";
    const audioUrl = data.audioUrl || "";
    const preamble = this.data.get("preamble") || "";
    const medias = data.medias || "[]"
    const cursorPosition = this.data.get("cursorPosition") || preamble.length;
    this.messageTarget.value =
      preamble.substring(0, parseInt(cursorPosition)) +
      content +
      preamble.substring(parseInt(cursorPosition));
    this.messageTarget.dispatchEvent(new Event("change"));

    const audioElem = document.getElementById(this.audioInputIdValue);
    if (audioElem) {
      audioElem.value = audioUrl;
      const change = new Event("change");
      audioElem.dispatchEvent(change);
    }

    const input = document.getElementById(this.mediaInputIdValue);
    input.value = medias;
    input.dispatchEvent(new Event('change'));

  }
}
