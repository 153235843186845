import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "sideCol", "mainCol" ]
  connect() {
  }

  toggleContent (){
    if (this.sideColTarget.classList.contains('mobile-hide') || this.mainColTarget.classList.contains('mobile-hide')) {
      this.sideColTarget.classList.toggle('mobile-hide')
      this.mainColTarget.classList.toggle('mobile-hide')
    }
  }
}
