import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  // Toggle User Menu
  clickUserMenu() {
    $('.user-menu-links').toggle();
    $('.menu-hide-block').toggle();
  }
  clickHideMenu() {
    $('.user-menu-links').toggle();
    $('.menu-hide-block').toggle();
  }

  clickElsewhere(event){
    if (event.target === document.getElementById('admin-menu-toggle')) {
      
    } else if(!this.adminMenu.contains(event.target) && this.isAdminOpen ) {
      
      this._closeSidebar(event)
    }
  }

  clickSidebar(event) {
    if (!this.adminMenuToggle.classList.contains('no-show')) {
      this.adminMenuToggle.classList.add('no-show')
      this.adminMenu.classList.add('open')
    } else {
      this._closeSidebar(event)
    }
  }

  _closeSidebar(event){
    if (event) {
      event.preventDefault()
    }
    this.adminMenuToggle.classList.remove('no-show')
    this.adminMenu.classList.remove('open')
  }

  // Toggle Info on Conference Call Contact
  clickContactMoreInfo() {
    $(this.element).toggleClass('open');
    $(this.element).next().toggleClass('open');
  }

  get adminMenu() {
    return document.getElementById("admin-menu")
  }

  get adminMenuToggle() {
    return document.getElementById("admin-menu-toggle")
  }

  get isAdminOpen(){
    return this.adminMenu.classList.contains('open')
  }

}
