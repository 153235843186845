import {
  Controller
} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["search", "list", 'tags', 'submit', 'sizer']

  connect() {
    this.disableSubmit()
    this.resizeInput()
    this.setInitialSelection()
  }

  filterList(event) {
    const element = this.searchTarget
    if (this.searchTarget.placeholder.length) {
      this.data.set('placeholder', this.searchTarget.placeholder)
    }
    this.searchTarget.placeholder = ''
    this.resizeInput()
    const copy = element.value
    if (event) {
      const key = event.keyCode || event.charCode
      if (copy.length == 0 && (key == 8 || key == 46) && this.data.get('filterLength') == '0') {
        this.removeLastSelection()
      }
    }
    this.listTargets.forEach(function(list){
    const listItems = $(list).children('li')
    if (copy) {
      listItems.hide()
      listItems.each((index, element) => {
        if (element.textContent.toLowerCase().includes(copy.toLowerCase())) {
          $(element).show()
        }
      })
    } else {
      listItems.show()
    }
  })
  }

  resizeInput() {
    if (this.hasSizerTarget) {
      this.sizerTarget.innerHTML = this.searchTarget.value
      const sizerWidth = $(this.sizerTarget).width()
      const $search = $(this.searchTarget)
      if (sizerWidth > 0 && this.searchTarget.value.length) {
        const width = Math.ceil(sizerWidth + 45);
        $search.css('width', width)
      } else {
        $search.css('width', this.defaultSearchWidth())
      }
    }
  }

  defaultSearchWidth() {
    const containerWidth = $(this.tagsTarget).width()
    const labels = $(this.tagsTarget).find('.selection-label').get()
    if (labels.length == 0) {
      return '100%'
    }
    const contentWidth = labels.map(function (val) {
      return $(val).outerWidth(true);
    }).reduce(function (acc, val) {
      return acc + val;
    });
    const width = contentWidth > containerWidth ? (containerWidth - (contentWidth % containerWidth) - (labels.length * 10)) : containerWidth - contentWidth
    return Math.max(Math.floor(width) - 60, 40)
  }

  disableSubmit() {
    if (this.hasSubmitTarget) {
      this.submitTarget.classList.add('disabled')
    }
  }

  enableSubmit() {
    if (this.hasSubmitTarget) {
      this.submitTarget.classList.remove('disabled')
    }
  }

  setInitialSelection() {
    const selected = this.listTargets.map((list) => {
      return $(list).find('input:checked').get()
    }).flat()
    selected.forEach((element) => {
      this.addTag(element)
    })
  }

  changeSelection(event) {
    const element = event.target
    if (element.checked) {
      this.addTag(element)
    } else {
      this.removeTag(element)
    }
    this.resizeInput()
    this._validateSubmit()
  }

  addTag(element) {
    if (this.hasSubmitTarget) {
      this.submitTarget.disabled = false;
    }
    if (this.hasTagsTarget) {
      const target = $(this.tagsTarget)
      const label = $(element).siblings('label').text()
      const html = `<span id="tag-${element.id}" class="selection-label" data-action="click->selectlist#tagClick" data-input-id="${element.id}">${label} <i class="delete icon"></i></span>`
      const lastLabel = target.find('.selection-label').last()
      if (lastLabel.length) {
        $(html).insertAfter(lastLabel)
      } else {
        target.prepend(html)
      }
      this.searchTarget.value = ""
      //this.searchTarget.focus()
      this.filterList()
    }
  }

  removeLastSelection() {
    const element = $(this.tagsTarget).find('.selection-label').last().get(0)
    if (element) {
      this.tagsTarget.removeChild(element)
      this._deselect(element)
    }
  }

  tagClick(event) {
    const element = event.currentTarget || event.target
    this.tagsTarget.removeChild(element)
    this._deselect(element)
  }

  removeTag(element) {
    const tag = document.getElementById(`tag-${element.id}`)
    if (this.hasTagsTarget && tag) {
      this.tagsTarget.removeChild(tag)
    }
  }

  storeFilter(event) {
    this.data.set("filterLength", this.searchTarget.value.length)
  }

  _deselect(element) {
    const id = element.dataset.inputId
    document.getElementById(id).checked = false;
    this._validateSubmit()
  }

  _validateSubmit() {
    if (this.hasListTarget) {
      const lists = this.listTargets
      if (lists.filter(l => l.querySelector('input:checked')).length == 0) {
        this.disableSubmit()
        if (this.data.has("placeholder")) {
          this.searchTarget.placeholder = this.data.get('placeholder')
        }
      } else {
        this.enableSubmit()
      }
    }
  }

}