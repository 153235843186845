import {
  Controller
} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["source", 'icon', 'text']
  copy(click) {
    click.stopPropagation()
    click.preventDefault()
    const str = this.sourceTarget.innerText
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (this.hasIconTarget) {
      this.iconTarget.classList.remove('copy')
      this.iconTarget.classList.add('check')
    }
    if (this.hasTextTarget){
      this.textTarget.innerText = "Copied"
      this.textTarget.classList.add('copied')
    }
  }
}